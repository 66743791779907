
import { ref, getCurrentInstance, reactive, onMounted } from "vue"
import Layout from "@components/Layout.vue"
import { Message } from "@models/Message.ts"
import { ElMessage } from 'element-plus'
import { initializeSession, sendMsg } from '@plugins/opentok'
import { startLoading } from '@utils/Axios'
import { joinSession, sendToServer } from "@requests/Support"

export default {
    name: "Chat",
    components : { Layout },

    setup() {

        // @ts-ignore
        const { proxy } = getCurrentInstance()

        const message = ref();
        const messages = reactive< Message[] >( [] )


        const sendMessage = ( content : string ) => {
            let msg = {
                content : content,
                from_support : true,
                type : "TEXT"
            }

            sendToServer( proxy.$route.params.id, msg )
                .then( res => sendMsg( content, message, msg ) )
        }


        onMounted( async () => {
            // rename messages from api to msgs
            const { token, session_id, messages : msgs } = await joinSession( proxy.$route.params.id )

            // load messages from database
            //msgs.forEach( ( msg ) => { messages.push( msg ) } )

            // connect to OT
            initializeSession( session_id, token, messages )
        } )

        return {
            sendMessage,
            messages,
            message,
        }
    },
}
